import { SingleStat } from "components/atoms/SingleStat";
import { FC } from "react";
import styled from "styled-components";
import { color, fonts } from "../../styles";
import { Tooltip } from "components/atoms/Tooltip";
import { tablet } from "components/templates/Breakpoints";

export interface Stat {
  value: string;
  description: string;
  tooltip?: string | React.ReactElement;
  tooltipDesktopWidth?: string;
  tooltipCopy?: string | React.ReactElement;
  marginRight?: string;
  marginLeft?: string;
  bold?: boolean;
  maxWidth?: string;
}

export interface NewStatSectionProps {
  heading?: string;
  stats: [Stat, Stat, Stat] | [];
  backgroundColor?: string;
  inverted?: boolean;
  maxWidth?: string;
  tooltipCopy?: string | React.ReactElement;
  tooltipDesktopWidth?: string;
  customBreakpoint?: string;
}

export const NewStatSection: FC<NewStatSectionProps> = ({
  heading,
  stats,
  backgroundColor,
  inverted,
  maxWidth,
  tooltipCopy,
  tooltipDesktopWidth = "18.75rem",
  customBreakpoint = "65rem",
}) => {
  return (
    <Wrapper
      heading={heading}
      backgroundColor={backgroundColor}
      $customBreakpoint={customBreakpoint}
    >
      {heading && (
        <HeadingSection inverted={inverted} $customBreakpoint={customBreakpoint}>
          <HeadingContainer>
            <HeadingTitle>
              {heading}
              {tooltipCopy && (
                <ToolTipWrap>
                  <Tooltip
                    inverted={inverted}
                    tooltipDesktopWidth={tooltipDesktopWidth}
                    isOnHeading
                  >
                    {tooltipCopy}
                  </Tooltip>
                </ToolTipWrap>
              )}
            </HeadingTitle>
          </HeadingContainer>
        </HeadingSection>
      )}
      <StatContainer maxWidth={maxWidth} heading={heading} $customBreakpoint={customBreakpoint}>
        <SingleStat inverted={inverted} stat={stats[0]} customBreakpoint={customBreakpoint} />
        <Line inverted={inverted} $customBreakpoint={customBreakpoint} />
        <SingleStat
          inverted={inverted}
          stat={{
            ...stats[1],
            marginRight: stats[1].marginRight ?? "-2rem",
          }}
          customBreakpoint={customBreakpoint}
        />
        <Line inverted={inverted} $customBreakpoint={customBreakpoint} />
        <SingleStat inverted={inverted} stat={stats[2]} customBreakpoint={customBreakpoint} />
      </StatContainer>
    </Wrapper>
  );
};

const HeadingTitle = styled.h2`
  font-family: ${fonts.heading};
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: 0rem;
  text-align: center;
  color: inherit;
  margin: 0;
`;

const HeadingSection = styled.div<{ inverted: boolean; $customBreakpoint: string }>`
  color: ${(p) => (p.inverted ? `${color.H100}` : `${color.white}`)};
  margin: 0;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: ${(p) => p.$customBreakpoint}) {
    padding-top: 5rem;
  }
`;

export const HorizontalLine = styled.div`
  border-bottom: 1px solid white;
  width: 12.5rem;
`;

export const Div = styled.div`
  @media only screen and (max-width: 54rem) {
    margin-left: 1rem;
  }
`;

export const Wrapper = styled.section<{
  backgroundColor: string;
  heading: string;
  $customBreakpoint: string;
}>`
  background: ${(p) => p.backgroundColor || color.S2100};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 0;

  @media only screen and (max-width: ${(p) => p.$customBreakpoint}) {
    padding: 0;
  }
`;

const StatContainer = styled.div<{ heading: string; maxWidth: string; $customBreakpoint: string }>`
  justify-items: center;
  align-items: center;
  padding-top: ${(p) => (p.heading ? "2.5rem;" : "0")};
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  overflow: visible;

  @media only screen and (max-width: ${(p) => p.$customBreakpoint}) {
    flex-direction: column;
    padding: ${(p) => (p.heading ? "0 0 5rem;" : "2.5rem 0 5rem")};
  }
`;

const Line = styled.div<{ inverted: boolean; $customBreakpoint: string }>`
  height: 4.125rem;
  border-left: ${(p) => (p.inverted ? `1px solid ${color.B240}` : "1px solid white")};

  @media only screen and (max-width: ${(p) => p.$customBreakpoint}) {
    height: 1px;
    border-left: none;
    border-bottom: ${(p) => (p.inverted ? `1px solid ${color.B240}` : "1px solid white")};
    width: 50%;
    padding: 1rem 0;
  }
`;

const ToolTipWrap = styled.span`
  margin-top: 0.5rem;
  position: relative;
  color: ${color.B1100};
  font-family: ${fonts.body};
`;

const HeadingContainer = styled.span`
  padding: 0 2rem;
`;

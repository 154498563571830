import { FC } from "react";
import styled from "styled-components";
import { TrustWidgetLarge } from "components/molecules/TrustBox/TrustWidgetLarge";
import { RequestCallback } from "../molecules/RequestCallback";
import { mobile } from "components/templates/Breakpoints";
import { KeyResources } from "components/molecules/KeyResources";
import { KeyResourcesItemProps } from "components/atoms/KeyResourcesItem";
import { StandardSection } from "components/templates/StandardSection";
import { P1 } from "components/atoms/Paragraph";

interface BottomSectionProps {
  keyResources?: KeyResourcesItemProps[];
  keyResourcesTitle?: string;
  keyResourcesType?: "blog" | "news-and-events" | "case-studies";
  requestCallbackProps?: {
    title?: string;
    subtitle?: string;
    buttonText?: string;
  };
  showRequestCallback?: boolean;
}

const BottomSection: FC<React.PropsWithChildren<BottomSectionProps>> = ({
  keyResources,
  requestCallbackProps,
  children,
  keyResourcesTitle,
  showRequestCallback = true,
  keyResourcesType = "blog",
}) => {
  return (
    <>
      <StandardSection pt="2rem">
        <TrustWidgetLarge mt="5rem" />
        <TrustCopyWrap>
          <TrustCopy bold>
            We are the #1 rated employee benefits platform on{" "}
            <TrustImg src="/static/logos/TrustPilotLogo.svg" alt="Trustpilot logo" />
          </TrustCopy>
        </TrustCopyWrap>
      </StandardSection>
      {children}
      <KeyResources
        title={keyResourcesTitle}
        resourcesInfo={keyResources}
        urlPrefix={keyResourcesType}
      />
      {showRequestCallback && (
        <RequestCallback
          title={requestCallbackProps?.title}
          subtitle={requestCallbackProps?.subtitle}
          buttonText={requestCallbackProps?.buttonText}
        />
      )}
    </>
  );
};

const TrustCopyWrap = styled.div`
  display: flex;
  padding: 1rem 0;
  margin: auto;
  text-align: center;
`;

const TrustCopy = styled(P1)`
  font-size: 1rem;

  ${mobile} {
    line-height: 0.8rem;
  }
`;

const TrustImg = styled.img`
  position: relative;
  top: 0.35rem;
  left: -0.1rem;
`;

export default BottomSection;

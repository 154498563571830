import { mobile, tablet } from "components/templates/Breakpoints";
import { H2 } from "components/atoms/Heading";
import { KeyResourcesItem, KeyResourcesItemProps } from "components/atoms/KeyResourcesItem";
import { FC } from "react";
import styled from "styled-components";
import { useLocale } from "@hooks";
import { generateAbsoluteUrl } from "@utils";

interface KeyResourcesProps {
  resourcesInfo?: KeyResourcesItemProps[];
  title?: string;
  urlPrefix?: "blog" | "news-and-events" | "case-studies";
}

export const KeyResources: FC<KeyResourcesProps> = ({
  resourcesInfo,
  title = "Key Resources",
  urlPrefix = "blog",
}) => {
  if (!resourcesInfo?.length) {
    return null;
  }

  const { locale } = useLocale();

  return (
    <Container>
      <Title align="center">{title}</Title>
      <ItemWrap>
        {(resourcesInfo || []).map(({ tag, contentType, readTime, title, slug, img }, index) => {
          return (
            <KeyResourcesItem
              key={`${title}-${index}`}
              title={title}
              tag={tag}
              contentType={contentType}
              readTime={readTime}
              slug={generateAbsoluteUrl(locale, `${urlPrefix}/${slug}/`)}
              img={img}
            />
          );
        })}
      </ItemWrap>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin: 3rem 0 7.5rem 0;
  padding: 0 5rem 0 5rem;

  ${mobile} {
    margin: 3.5rem 0 3.5rem 0;
  }
`;

const Title = styled(H2)`
  ${mobile} {
    text-align: center;
  }
`;

const ItemWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2.625rem;
  margin-top: 2.5rem;
  row-gap: 2.5rem;

  ${mobile} {
  }
`;
